import React from 'react';
import LoadMoreGrid from 'shared/load-more-grid';

export default function ProjectsGrid({ featuredList, ...props }) {
  const cards = featuredList.map(({ featured, slug }) => ({
    project_name: featured.title,
    services_provided: featured.services_provided,
    image: featured.large_image,
    href: slug
  }));
  return <LoadMoreGrid {...{ cards }} />;
}
