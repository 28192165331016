import React from 'react';
import Meta from 'meta';
import Hero from 'components/page-our-work/hero';
import FeaturedProjects from 'components/page-our-work/featured-projects';
import ProjectsGrid from 'components/page-our-work/projects-grid';
import Testimonial from 'shared/testimonial';
import { testimonial } from 'utils/randomTestimonial';
import LetsStart from 'shared/lets-start';

export default function OurWork({ pageContext, ...props }) {
  const { featuredList } = pageContext;
  const showcasedAndRandomized = featuredList
    .filter(({ featured }) => featured.showcase)
    .sort(() => Math.random() - 0.5);
  const theRest = featuredList.filter(({ featured }) => !featured.showcase);
  return (
    <>
      <Meta
        title="Brand Specific Websites | Our Projects | CXN Collective"
        description="From building luxury brands to integrating eCommerce and in-store campaigns, CXN continuously collaborates and grows with its partners."
        canonical="https://cxncollective.com/our-work/"
      />
      <Hero />
      <main>
        <FeaturedProjects
          {...{ featuredList: showcasedAndRandomized.slice(0, 3) }}
        />
        <Testimonial {...testimonial} />
        {featuredList.slice(3).length > 0 && (
          <ProjectsGrid
            {...{
              featuredList: [...showcasedAndRandomized.slice(3), ...theRest]
            }}
          />
        )}
        <LetsStart />
      </main>
    </>
  );
}
