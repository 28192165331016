import React from 'react';
// import Container from 'container';
// import Button from 'button';
// import Headline from 'headline';
// import Img from 'img';
// import { navigate, Link } from 'gatsby';
import OurWork from 'shared/our-work';

// import s from './featured-projects.module.scss';
const data = {
  title: 'Our work',
  subtitle:
    'Interested in our case studies? Here is a list of past work that involved digital, branding, advertising & much more.',
  button1: {},
  button2: {
    text: 'READY TO START',
    href: '/contact/'
  }
};
export default function ProjectGrid({ featuredList, ...props }) {
  // const projects = featuredList.map(({ featured, slug }) => ({
  //   project_name: featured.title,
  //   services_provided: featured.services_provided,
  //   image: featured.image,
  //   href: slug
  // }));
  const cards = featuredList
    .map(({ featured, slug }) => ({
      title: featured.title,
      subtitle: featured.services_provided,
      small_image: featured.small_image,
      large_image: featured.large_image,
      href: slug,
      showcase: featured.showcase
    }))
    .filter(({ showcase }) => showcase);
  return <OurWork {...{ cards, ...data }} />;
  // <Container className={s.root}>
  //   <Button big className={s.button}>
  //     <span className={s.black}>SHOWING:</span>
  //     ALL
  //   </Button>
  //   <ul className={s.projectsWrapper}>
  //     {projects.map(({ image, project_name, services_provided, href }, i) => (
  //       <li
  //         className={s.featured}
  //         key={`${project_name}-${i}`}
  //         onClick={() => {
  //           navigate(href);
  //         }}
  //       >
  //         <div className={s.blur} />
  //         <Img className={s.img} src={image.title} alt={image.title} />
  //         <div className={s.info}>
  //           <Link to={href}>
  //             <Headline h3 el="p" className={s.projectName}>
  //               {project_name}
  //             </Headline>
  //           </Link>
  //           <Headline h3 el="p" className={s.servicesProvided}>
  //             {services_provided}
  //           </Headline>
  //         </div>
  //       </li>
  //     ))}
  //   </ul>
  // </Container>
}
