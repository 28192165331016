import React from 'react';
import useSetScrollRef from 'hooks/useSetScrollRef';
import HeroWrapper from 'shared/hero-wrapper';

import s from './hero.module.scss';

export default function HomeHero() {
  const hero = useSetScrollRef();
  return (
    <HeroWrapper img="cxn-hero-1" className={s.hero} ref={hero}>
      <img
        src="/svg/cxn-lasting-connections.svg"
        alt="cxn-lasting-connections"
        className={s.img}
      />
    </HeroWrapper>
  );
}
